
let Settings = (()=>{
    let me = {
        version: "0.0.13",
        skipLogin: false,
        autoTranslate: false,
        useIncidentComments: true,
        useIncidentActions: true,
        stripNumbersFromIncidentCaption: true,
    };

    me.getTheme = ()=>{
        return localStorage.getItem("theme") || "dark";
    }

    me.setTheme = (theme)=>{
        localStorage.setItem("theme",theme);
    }

    me.getLanguage = ()=>{
        return localStorage.getItem("language") || "fr";
    }

    me.setLanguage = (language)=>{
        localStorage.setItem("language",language);
    }


    return me;
})();
export default Settings;