import api from "../api.js";
import $ from "../util/dom.js";
import incidentService from "../services/incidentService.js";
import translations from "../translations.js";
import {cleanString, formatDate} from "../util.js";
import Settings from "../settings.js";
import TranslationService from "../services/translationService.js";
import User from "../user.js";
import MasterDetail from "../components/masterDetail.js";
import dataStructure from "../data.js";
import IncidentService from "../services/incidentService.js";

let IncidentsUI = (()=>{
    let me = {};

    let labels={
        platform_category:"Category",
        report_date:"Date",
        platform_status:"Status",
        platform_status_update:"Status update",
        report_language:"Language",
        report_facility:"Province",
        report_mining:"Mining",
        report_mining_numb:"Mining number",
        report_corruption:"Corruption",
        platform_urgency:"Urgency",
        report_who:"Who",
        report_violence:"Violence",
        report_violence_who:"Violence who",
        report_violence_numb:"Violence number",
        report_environment:"Environment",
        report_environment_who:"Environment who",
        lead_organization:"Lead organization",
        report_childlabor:"Child labor",
        report_childlabor_other:"Child labor other",
        report_childlabor_numb:"Child labor number",
        report_roadblock:"Roadblock",
        report_roadblok:"Roadblock",
    };

    let ignoreKeys=[
        "id",
        "report_id",
        "report_informant",
        "platform_title",
        "platform_description",
        "platform_location",
        "report_location",
        "platform_location_latitude",
        "platform_location_longitude",
        "coordinates",
        "latitude",
        "longitude",
        "report_category",
        "report_describ",
        "info_id",
        "lastModified",
        "sector",
        "group",
        "miningsite",
        "isCLS",
        "description",
        "title"
    ];

    let ignorePrefix=[
        "platform_comment_"
    ];


    let translateKeys=[
        "platform_category",
        "category",
        "subcategory",
        "report_environment",
        "report_environment_who",
        "report_mining",
        "report_violence",
        "report_violence_who",
        "report_language",
        "report_childlabor",
        "report_childlabor_other"
    ];

    let combinedKeys=[];

    let autoTranslateKeys=[];


    let resolveKeys={
        assigned: async (value)=>{let item = await api.getById("organisations",value); return item?item.name:""},
    }

    me.renderDetail = async (item,canEdit)=>{
        let mapView,coElm;

        let data = await api.getById("incidents_external",(item.report_id || item.id),true);
        if (!data || Object.keys(data).length === 0){
            data = item;
        }

        let language = data.language;
        let userlanguage = Settings.getLanguage();
        let infoTab,infoTabContent;
        let showTabs = Settings.useIncidentComments || Settings.useIncidentActions;

        let title = data.platform_title || data.incident_title || data.title;
        let detailElm = $(".detail",
            infoTab = $(".tab",
                showTabs?$("h4.tabtitle",{onClick:()=>{
                        infoTab.classList.toggle("collapsed");
                    }},translations.data_by_monitor
                ):undefined,
                infoTabContent = $(".tabcontent",
                    title?$("h4",TranslationService.box(title,language,userlanguage)):undefined,
                    mapView = $("#mapView",$(".spinner")),
                    coElm = $(".coordinates")
                ),
            ),
        );



        mapView.innerHTML = ""

        let lat = data.latitude || data.platform_location_latitude;
        let lng = data.longitude || data.platform_location_longitude;
        lat = parseFloat(lat);
        lng = parseFloat(lng);

        if (!lat || !lng){
            let co = IncidentService.getGeoLocationFromData(data);
            lat = co.lat;
            lng = co.lng;
        }

        if (lng && lat){
            coElm.innerHTML = lat + ", " + lng;
            showMap(mapView,lat,lng,true,coElm);
        }else{
            mapView.style.display = "none";
        }

        $("div",{parent:infoTabContent},
            $(".location",data.location || data.platform_location),

            $("h4",translations.description),
            $(".description",TranslationService.box(data.report_describ || data.description,language,userlanguage)),

            $("h4",translations.information),
            renderDataTable(data,language,userlanguage,canEdit),

            (data.fpic && data.fpic_URL)?$(".files",
                $("h4",translations.image),
                $("a",{href:data.fpic_URL,target:"_blank"},$("img",{src:data.fpic_URL}),data.fpic)
            ):undefined
        )


        if (Settings.useIncidentComments) renderCommentTab(data,detailElm);
        if (canEdit && Settings.useIncidentActions) renderActionTab(data,detailElm);

        return detailElm;
    };

    function renderDataTable(data,language,userlanguage,canEdit){
        let autoTranslate = Settings.autoTranslate;
        let map = {
            h1:"h1",
        };

        //console.error(data);

        let struct = dataStructure.incidentsGenericInfo.concat( dataStructure.incidentsInfo.fields);

        let captionCount = 0;
        struct.forEach(field=>{
            let key = field.key;
            if (field.type === "caption"){
                captionCount++;
                key = "h" + captionCount;
                map[key] = field;
            }
            let value = data[key];
            if (value){
                map[key] = value;
            }
            if (field.key === "typology"){
                Object.keys(data).forEach(key=>{
                    if (key.startsWith("typology_")){
                        map[key] = data[key];
                    }
                });
            }
        })

        Object.keys(data).forEach(key=>{
            let value = data[key];

            if (value && !map[key]){
                console.error("Missing key",key);
                map[key] = value;
            }
        });



        return $("table",Object.keys(map).map(key=>{
            if (ignoreKeys.indexOf(key)>=0) return;
            let ignore = false;
            ignorePrefix.forEach(prefix=>{
                if (key.startsWith(prefix)) ignore = true;
            });
            if (ignore) return;
            let value = map[key];

            if (value && value.type === "caption"){
                let label = value.label;
                if (Settings.stripNumbersFromIncidentCaption){
                    if (label && label.indexOf(":")>=0);
                    label = label.split(":").pop().trim();
                }

                let td = $("td",label);
                td.setAttribute("colspan","2");
                return $("tr.caption",td);
            }

            if (key==="report_date") value = formatDate(value);
            if (key==="date_start") value = formatDate(value);
            if (key==="date_end") value = formatDate(value);

            let td = $("td",""+value);

            if (resolveKeys[key]){
                value = resolveKeys[key](value);
                if (value && value.then){
                    value.then(value=>{
                        td.innerText = value;
                    });
                }else{
                    td.innerText = value;
                }
            }

            if (translateKeys.indexOf(key)>=0){
                let translated = translations[cleanString(value)];
                if (translated){
                    td.innerText = translated;
                }else{
                    console.error("Missing translation for",value);
                }
            }

            if (combinedKeys.indexOf(key)>=0){
                let parts = value.split(", ");
                let result = [];
                parts.forEach(part=>{
                    let p = part.indexOf("(");
                    let other ="";
                    if (p>0){
                        other = part.substring(p);
                        part = part.substring(0,p).trim();
                    }
                    let translated = translations[cleanString(part)];
                    if (translated){
                        let v = translated;
                        if (other) v += " " + other;
                        result.push(v);
                    }
                });
                if (result.length){
                    td.innerText = result.join(", ");
                }
            }


            if (autoTranslateKeys.indexOf(key)>=0 && autoTranslate && language !== userlanguage){
                let t = td.innerText;
                td.innerText = "";
                td.appendChild(TranslationService.box(t,language,userlanguage))
            }

            if (typeof value === "boolean"){
                td.innerText = value?translations.yes:translations.no;
            }

            if ((User.isOwner() || canEdit) &&  key === "monitor_id"){
                td.innerText = "";
                let v = $("span",{parent:td},value);
                $("span.infodot",{parent:td,onClick:()=>{
                        api.list("monitors",{shortname:value}).then(monitors=>{
                            v.innerHTML = "";
                            if (monitors && monitors.length){
                                let monitor = monitors[0];
                                v.appendChild($("div",$("div",value),$("div",monitor.name),$("div",monitor.phone)));
                            }else{
                                v.appendChild($("div",$("div",value),$("div",translations.unknownMonitor)));
                            }

                        });

                    }},"i");
            }


            if (value){
                let label = labels[key] || key;
                let translated = translations[label.toLowerCase().split(" ").join("_")];
                label = translated || label;
                return $("tr",$("td",""+label),td);
            }
        }));
    }


    async function renderCommentTab(incident,parent){

        let tab,form,list;
        tab=$(".tab",{parent});

        let data = await incidentService.getComments(incident);

        $("h4.tabtitle",{parent:tab,onClick:()=>{
                    tab.classList.toggle("collapsed");
                }},$("span",translations.comments + ' (' + data.length + ')'),
            $("button.add.addcomment.secondary",{onClick:()=>{
                    tab.classList.remove("collapsed");
                    form.style.display = "block";
                    setTimeout(()=>{
                        let textarea = form.querySelector("textarea");
                        textarea.focus();
                        textarea.scrollIntoView({ behavior: "smooth"})
                    },10);
                }},translations.addComment),
        );

        $(".tabcontent", {parent:tab},
            form = $(".commentform",$("textarea"),
                $("button.secondary.green.arrow",{onClick:()=>{
                        let comment = form.querySelector("textarea").value;
                        if (comment){
                            list.innerHTML = "";
                            list.appendChild($(".spinner"));
                            incidentService.postComment(comment,incident).then(()=>{
                                incidentService.getComments(incident).then(comments=>{
                                    list.innerHTML = "";
                                    list.appendChild($("div",renderComments(comments)));
                                });
                            });

                        }
                        form.style.display = "none";
                    }},translations.save),
                $("button.secondary.red.delete",{onClick:()=>{
                        form.style.display = "none";
                    }},translations.cancel)
            ),
            list = $(".commentlist", renderComments(data)),
        );
    }

    async function renderActionTab(incident,parent){



        let tab,form,list
        tab = $(".tab",{parent});
        let data = await incidentService.getActions(incident);

        $("h4.tabtitle",{parent:tab,onClick:()=>{
                    tab.classList.toggle("collapsed");
                }},$("span",translations.actions + ' (' + data.length + ')'),
            $("button.add.addcomment.secondary",{onClick:()=>{
                    tab.classList.remove("collapsed");
                    form.style.display = "block";
                }},translations.addAction),
        );
        $(".tabcontent",{parent:tab},
            form = $(".commentform",
                MasterDetail.generateForm(
                    dataStructure.actions,
                    (obj)=>{
                        console.error(obj);
                        form.style.display = "none";
                        list.innerHTML = "";
                        list.appendChild($(".spinner"));
                        incidentService.postAction(obj,incident).then(()=>{
                            incidentService.getActions(incident).then(actions=>{
                                list.innerHTML = "";
                                list.appendChild($("div",renderActions(actions,incident,list)));
                            });
                        });
                    },
                    ()=>{
                        form.style.display = "none";
                    }
                )
            ),
            list = $(".commentlist", renderActions(data,incident,list)),
        );

        return tab;
    }

    function renderComments(comments){
        if (comments.length===0) return $("small",translations.noComments);
        let userlanguage = Settings.getLanguage();
        return comments.map(
            comment=>{
                let lan = comment.language || "en";
                let tcomment = (lan === userlanguage) ?
                    $(".comment",comment.comment)
                    : $(".comment",TranslationService.box(comment.comment,lan,userlanguage));
                return $(".commentbox",$(".author",comment.user),tcomment,$(".date",comment.date))
            }
        );
    }

    function renderActions(actions,incident,list){
        if (actions.length===0) return $("small",translations.noActions);
        let config = dataStructure.actions;
        let userlanguage = Settings.getLanguage();

        if (list){
            let tab = list.closest(".tab");
            if (tab){
                let title = tab.querySelector(".tabtitle span");
                if (title) {
                    title.innerText = translations.actions + " (" + actions.length + ")";
                }
            }
        }

        return actions.map(action=>{
            let box = $(".actionbox")
            renderAction(action,box,config,userlanguage,incident);
            return box;
        });
    }

    function renderAction(action,box,config,userlanguage,incident){
        box.innerHTML = "";
        $(".inner",
            {parent:box},
            $(".author",action.user),
            $(".action",
                $("table",
                    config.fields.filter(a=>a.isPrimary).map(field=>{
                        let value = action[field.key];

                        if (field.values){
                            if (field.type === "multiselect"){
                                value = value.split(", ").map(val=>{
                                    let v = field.values.find(v=>v.value===val);
                                    if (v) return v.label;
                                    return value;
                                }).join(", ");
                            }else{
                                let v = field.values.find(v=>v.value===value);
                                if (v) value = v.label;
                            }
                        }

                        if (field.translateContent){
                            let lan = action.language || "en";
                            if (lan !== userlanguage){
                                value = TranslationService.box(value,lan,userlanguage);
                            }
                        }

                        console.error(field);

                        return $("tr",
                            $("td",field.label),
                            $("td",value)
                        );
                    })
                )
            ),
            User.isAdmin() && $("button.edit.float.tertiary",{onClick:()=>{
                    editAction(action,box,config,userlanguage,incident);
                }},translations.edit),
            $(".date",action.date)
        )
    }

    function editAction(action,box,config,userlanguage,incident){
        box.innerHTML = "";

        let form = MasterDetail.generateForm(
            dataStructure.actions,
            (obj)=>{
                form.style.display = "none";
                let list = box.closest(".commentlist");
                list.innerHTML = "";
                obj.id = action.id;
                list.appendChild($(".spinner"));
                incidentService.postAction(obj,incident).then(()=>{
                    incidentService.getActions(incident).then(actions=>{
                        list.innerHTML = "";
                        list.appendChild($("div",renderActions(actions,incident,list)));
                    });
                });
            },

            ()=>{
                renderAction(action,box,config,userlanguage);
            },
            ()=>{
                incidentService.deleteAction(action).then(()=>{
                    let list = box.closest(".commentlist");
                    list.innerHTML = "";
                    list.appendChild($(".spinner"));
                    incidentService.getActions(incident).then(actions=>{
                        list.innerHTML = "";
                        list.appendChild($("div",renderActions(actions,incident,list)));
                    });
                });
            },
            action
        );

        box.appendChild(form);


    }


    function showMap(parent,lat,lng,dragMarker,coElm){
        mapboxgl.accessToken = 'pk.eyJ1IjoiaXBpc3Jlc2VhcmNoIiwiYSI6IklBazVQTWcifQ.K13FKWN_xlKPJFj9XjkmbQ';
        const map = new mapboxgl.Map({
            container: parent,
            style: 'mapbox://styles/mapbox/streets-v12', // style URL
            center: [lng, lat], // starting position [lng, lat]
            zoom: 9 // starting zoom
        });

        const marker = new mapboxgl.Marker({draggable: dragMarker})
            .setLngLat([lng, lat])
            .addTo(map);

        marker.on('dragend', ()=>{
            const co = marker.getLngLat();
            coElm.innerHTML = co.lat.toFixed(7) + ", " + co.lng.toFixed(7);
            //coordinates.style.display = 'block';
            //coordinates.innerHTML = `Longitude: ${lngLat.lng}<br />Latitude: ${lngLat.lat}`;
        });


    }



    return me;

})();

export default IncidentsUI;