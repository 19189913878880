import masterDetail from "../components/masterDetail.js";
import dataStructure from "../data.js";
import api from "../api.js";
import $ from "../util/dom.js";
import translations from "../translations.js";
import {formatDate,cleanString} from "../util.js";
import incidentService from "../services/incidentService.js";
import nav from "../components/nav.js";
import IncidentsUI from "../../_script/views/incidents.js";


let IncidentsSms = (()=>{
    let me = {};

    me.render = async (container,params,id)=>{
        let config = Object.assign({},dataStructure.incidentsSMS);
        config.openDetailId = id;

        if (params && params.filter){
            config.preFilterName = params.filter;
            if (params.filter==="assigned"){
                config.preFilter = item=>!!item.assigned && !item.archived
            }

            if (params.filter==="nonassigned"){
                config.preFilter = item=>!item.assigned && !item.archived;
            }

            if (params.filter==="archived"){
                config.preFilter = item=>!!item.archived;
            }

        }

        config.detail= async (item,formContainer)=>{

            window.location.hash = "incidents-sms/" + item.report_id || item.id;

            formContainer.innerHTML = "<h3>Incident</h3>";
            formContainer.appendChild($(".closebutton.light",{onClick:masterDetail.closeSidePanel}));

            console.log("Incident",item);
            formContainer.appendChild(await IncidentsUI.renderDetail(item),false);


            let actionPanel = $(".incidentactions",
                {parent:formContainer},
                $(".slidepanel"),
                $(".buttonsbar",
                    $("button.primary.green.arrow",
                        {onClick:()=>{
                                showAssignForm(actionPanel,item);
                        }},
                        translations.assigntoCSO),
                    (!item.archived && $("button.primary.red.delete",
                        {onClick:()=>{
                                showArchiveForm(actionPanel,item);
                            }},
                        translations.archive)),
                    $("button.primary.blue.arrow",
                        {onClick:()=>{
                                masterDetail.closeSidePanel();
                            }},
                        translations.cancel)
                )
            );
        };

        // preload organisations
        await api.list("organisations");
        await api.list("users");
        await masterDetail.render(container,config);

    }


    function showArchiveForm(parent,item){
        let slidePanel = parent.querySelector(".slidepanel");
        let form = $(".actionform",
            {parent:slidePanel},
            $("h3",translations.archive),
            $(".content",
                $("p",translations.archiveReason),
                $("div", $("textarea",{placeholder:translations.archiveReasonExample})),
                $("button.secondary.blue.arrow",{
                    onClick:()=>{
                        let textarea = form.querySelector("textarea");
                        let reason = textarea.value;
                        if (reason){
                            let from = "nonassigned";
                            if (item.assigned) from = "assigned";
                           incidentService.archive(item,true,reason).then(()=>{
                                masterDetail.refresh();
                                closeForm();
                                nav.animateUpdate(from,"archived",1);
                            });
                        }else{
                            textarea.classList.remove("error");
                            setTimeout(()=>{
                                textarea.classList.add("error");
                            },10);
                        }
                    }
                },translations.archive),
                $("button.secondary.red.delete",
                    {onClick:closeForm},
                    translations.cancel)
            )
        );
        parent.classList.add("active");
        setTimeout(()=>{
            form.classList.add("active");
        },10);

        function closeForm(){
            form.classList.remove("active");
            setTimeout(()=>{
                parent.classList.remove("active");
                slidePanel.removeChild(form);
            },300);
        }
    }

    async function showAssignForm(parent,item){
        let slidePanel = parent.querySelector(".slidepanel");
        let selectPanel;
        let form = $(".actionform",
            {parent:slidePanel},
            $("h3",translations.assigntoCSO),
            $(".content",
                item.assigned?$(".warningbox",translations.alreadyAssigned):undefined,
                $("p",translations.assignIntro + " " + translations.assignEmail),
                selectPanel = $("div",$(".spinner")),
                $("button.secondary.blue.arrow",{
                    onClick:()=>{
                        let select = selectPanel.querySelector("select");
                        let organisation = select.value;
                        if (organisation){
                            let from = "nonassigned";
                            if (item.assigned) from = "assigned";
                            incidentService.assignToCSO(item,organisation).then(()=>{
                                masterDetail.refresh();
                                closeForm();
                                nav.animateUpdate(from,"assigned",1);
                            });
                        }else{
                            select.classList.remove("error");
                            setTimeout(()=>{
                                select.classList.add("error");
                            },10);
                        }
                    }

                },translations.assigntoCSO),
                $("button.secondary.red.delete",
                    {onClick:closeForm},
                    translations.cancel)
            )
        );
        parent.classList.add("active");
        setTimeout(()=>{
            form.classList.add("active");
        },10);

        function closeForm(){
            form.classList.remove("active");
            setTimeout(()=>{
                parent.classList.remove("active");
                slidePanel.removeChild(form);
            },300);
        }


        let list = await api.distinct("organisations","name");
        selectPanel.innerHTML = "";
        $("select",{parent:selectPanel},
            $("option",{value:""},"--- " + translations.pleaseSelect),list.map(item=>$("option",{value:item.id},item.name)));
    }

    return me;
})();
export default IncidentsSms;