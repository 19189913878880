import $ from "../util/dom.js";
import page from "../views/page.js";
import api from "../api.js";
import translations from "../translations.js";
import {cleanString,formatDate,intDate} from "../util.js";
import user from "../user.js";
import dataStructure from "../data.js";

let MasterDetail = (()=>{
    let me = {};
    let formContainer;
    let filterButtons = {};
    let currentConfig;
    let currentItemList;
    let currentDataItem;
    let currentNav;

    me.render = async (container,config)=>{
        page.waiting(true);
        let row,searchRow,itemList,buttons;
        currentConfig = config;

        $(".list",
            {parent:container},
            formContainer = $(".sideform",{parent:container}),
            $(".sidescroll",
                row = $(".header"),
                searchRow = $(".searchrow",{parent:container}),
                itemList = $(".itemlist",$(".loadertext","Chargement")),
            ),
            buttons = $(".buttons")
        );

        let first = true;
        config.fields.forEach(field=>{
            if (!field.isPrimary) return;

            let className = field.className || "";
            if (className) className = "." + className;
            if (config.sort === field.key){
                className += "." + config.sortOrder || "asc";
            }

            let label;
            row.appendChild(label = $("label.cell"+className,{
                    onClick:()=>{
                        if (config.sort === field.key){
                            config.sortOrder = config.sortOrder === "asc"?"desc":"asc";
                        }else{
                            config.sortOrder = "asc";
                        }
                        config.sort = field.key;
                        row.querySelectorAll("label").forEach(label=>{
                            label.classList.remove("asc","desc");
                        });
                        label.classList.add(config.sortOrder);
                        renderList(config,itemList);
                    }
                },
                filterButtons[field.key] = $(".filterbutton",{onClick:()=>{
                        if (!searchRow.innerHTML) renderSearch(config,searchRow,itemList,label);
                        searchRow.classList.toggle("active");
                    }
                }),
                field.label)
            );


            if (field.size){
                let size = field.size;
                if (first) size += 32;
                label.style.width = size + "px";
            }
            first = false;
        });

        currentNav = $(".nav",{parent:buttons});

        let canAdd = config.canAdd;
        if (config.preFilterName){
            if (config.preFilterName === "open") canAdd = false;
            if (config.preFilterName === "closed") canAdd = false;
        }

        if (canAdd){
            $("button.primary.green.add",{parent:buttons,onClick:()=>{
                    generateForm(config);
                }},translations.add);
        }


        $("button.primary.blue.export",{parent:buttons,onClick:(e)=>{
                let button = e.target.closest("button");
                if (button) button.classList.add("loading");
                exportData(config).then(()=>{
                    if (button) button.classList.remove("loading");
                });
            }},$(".spinner"),translations.export);

        $("button.primary.delete.filterbutton.hidden",{parent:buttons,onClick:(e)=>{
                config.filter = undefined;
                searchRow.innerHTML = "";
                if (searchRow.classList.contains("active")){
                    renderSearch(config,searchRow,itemList);
                }
                for (let key in filterButtons){
                    filterButtons[key].classList.remove("active");
                }
                renderList(config,itemList);
            }},translations.clearFilter);

        if (config.buttons){
            config.buttons.forEach(button=>{
                $("button.primary.green",{parent:buttons,onClick:button.onClick},$(".spinner"),button.label);
            });
        }

        api.resolveAll(config).then(()=>{
            renderList(config,itemList).then(count=>{});
        });
    }

    me.closeSidePanel = ()=>{
        let form = formContainer.querySelector(".form");
        let form2 = formContainer.querySelector(".form2");
        if( form){
            form.classList.add("out");
            setTimeout(()=>{
                formContainer.classList.remove("active");
            },500);
        }
        if( form2){
            form2.classList.add("out");
        }

        let hash = window.location.hash;
        if (hash.indexOf("/")>0){
            let parts = hash.split("/");
            let id = parseInt(parts.pop());
            if (!isNaN(id)){
                window.location.hash = parts.join("/");
            }
        }
    }

    me.refresh = ()=>{
        renderList(currentConfig,currentItemList);
    }

    me.double = async function(config,readOnly){
        formContainer.classList.add("double");
        let form2= formContainer.appendChild($(".form.form2.generic",$("h4",translations.informationAfterFollowUp)));

        let obj = currentDataItem;

        if (obj.id && typeof obj.id === "string" && obj.id.indexOf("-")>0){
            await api.getById("incidents",obj.id).then(result=>{
                if (result){
                    let newKeys = Object.keys(result);
                    let oldKeys = Object.keys(obj);
                    newKeys.forEach(key=>{
                        if (!oldKeys.includes(key)){
                            obj[key] = result[key];
                        }
                    });
                }
            });
        }
        //readOnly = false;
        if (readOnly){
            $(".detail.indent",{parent:form2},

                $("table",{parent:form2},config.fields.map(field=>{
                    let key = field.key;
                    let value = obj[key];
                    let show = !field.hideOnEdit;

                    if (!show) return;

                    if (value && field.translate){
                        value = translations[cleanString(value)] || value;
                    }
                    //console.log(field.type)
                    if (field.format === "date" || field.key === "lastModified"){
                        value = formatDate(value);
                    }
                    if (field.type === "boolean"){
                        value = value?translations.yes:translations.no;
                    }

                    let td = $("td",""+value);

                    if (value || typeof value === "number"){
                        let label = translations[cleanString(field.label)] || field.label || key;
                        let translated = translations[label.toLowerCase().split(" ").join("_")];
                        label = translated || label;
                        return $("tr",$("td",""+label),td);
                    }
                })));

            $(".buttons",{parent:form2},
                $("button.primary.red.delete",{onClick:()=>{
                    me.closeSidePanel();
                }},translations.close)
            );

            return;
        }


        config.editors=[];
        config.fields.forEach(field=>{
            let show = !field.hideOnEdit;
            if (field.scope === "owner" && !user.isOwner()) show = false;

            if (field.type === "caption"){
                $("h5",{parent:form2},field.label);
            }else{
                if (show) config.editors.push(generateEditor(form2,field,obj,config));
            }
        });

        let buttonSave;
        $(".buttons",{parent:form2},
            buttonSave = $("button.primary.green",{onClick:()=>{
                let obj = {};


                /*

                config.editors.forEach(editor=>{
                    let value = editor.input?editor.input.value:editor.getValue();
                    if (value || typeof value === "boolean" || typeof value === "number") obj[editor.key] = value;

                    if (editor.input) editor.input.classList.remove("error");
                    if (editor.element) editor.element.classList.remove("error");

                    let field =config.fields.find(field=>field.key===editor.key);
                    if (field){
                        if (field.isRequired && !value){
                            isValid = false;
                            if (editor.input) editor.input.classList.add("error");
                            if (editor.element) editor.element.classList.add("error");
                        }
                    }
                });

                 */

                let isValid = true;
                config.editors.forEach(editor=>{
                    let value = editor.input?editor.input.value:editor.getValue();
                    if (value || typeof value === "boolean" || typeof value === "number"){
                        obj[editor.key] = value;
                    }else{
                       let field = config.fields.find(field=>field.key===editor.key);
                       if (field && field.allowEmpty){
                            obj[editor.key] = value;
                       }
                    }

                    if (editor.input) editor.input.classList.remove("error");
                    if (editor.element) editor.element.classList.remove("error");
                    let field =config.fields.find(field=>field.key===editor.key);

                    if (field){
                        if (field.isRequired && !value){
                            isValid = false;
                            if (editor.input) editor.input.classList.add("error");
                            if (editor.element) editor.element.classList.add("error");
                        }
                    }

                });

                if (!obj.id && currentDataItem.id) obj.id = currentDataItem.id;

                if (config.profile === "incidents_info"){
                    obj.id = currentDataItem.info_id;
                }

                if (!isValid){
                    form2.classList.add("inerror");
                    buttonSave.classList.add("error");
                    setTimeout(()=>{
                        buttonSave.classList.remove("error");
                    },1000);
                    return;
                }

                api.update(config.profile,obj).then(result=>{
                    if (config.profile === "incidents_info"){
                        obj.id = currentDataItem.id;
                    }
                    api.updateCache("incidents_assigned",obj)
                    me.refresh();
                    me.closeSidePanel();
                });
            }},translations.save),
            $("button.primary.red.delete",{onClick:()=>{
                me.closeSidePanel();
            }},translations.cancel),
            config.additionalButtons?config.additionalButtons(obj):undefined
        );

    }

    me.generateForm = (config,onSave,onCancel,onDelete,obj)=>{
        let form = $(".generic");
        config.editors = [];
        config.fields.forEach(field=>{
            config.editors.push(generateEditor(form,field,obj));
        });
        $(".errormessage",{parent:form},translations.fill_in_required_fields);

        let button;
        $(".buttons",{parent:form},button = $("button.secondary.green",{onClick:()=>{
                let obj = {};
                let isValid = true;
                form.classList.remove("inerror");
                button.classList.remove("error");
                config.editors.forEach(editor=>{
                    let value = editor.input?editor.input.value:editor.getValue();
                    if (value || typeof value === "boolean" || typeof value === "number") obj[editor.key] = value;

                    if (editor.input) editor.input.classList.remove("error");
                    if (editor.element) editor.element.classList.remove("error");

                    let field =config.fields.find(field=>field.key===editor.key);
                    if (field){
                        if (field.isRequired && !value){
                            isValid = false;
                            if (editor.input) editor.input.classList.add("error");
                            if (editor.element) editor.element.classList.add("error");
                        }
                    }
                });

                if (!isValid){
                    button.classList.add("error");
                    form.classList.add("inerror");
                    return;
                }

                onSave(obj);

            }},translations.save),
            $("button.secondary.blue.delete",{onClick:onCancel},translations.cancel),
            onDelete && obj && obj.id?$("button.secondary.red.delete",{onClick:()=>{
                    onDelete(obj);
                }},translations.delete):undefined
        );

        return form;
    }

    function renderSearch(config,parent,itemList){
        let first = true;
        config.fields.forEach(field=>{
            if (!field.isPrimary) return;
            let className = field.className || "";
            if (className) className = "." + className;
            let input,cell;
            parent.appendChild(cell=$("label.cell"+className,
                input=$("input",{
                    placeholder:field.label,
                    onkeyup:()=>{
                        let value = input.value.toLowerCase();
                        config.filter = config.filter || {};
                        config.filter[field.key] = config.filter[field.key] || {};
                        config.filter[field.key].match = value;
                        api.resolveAll(config).then(()=>{
                            renderList(config,itemList);
                        });
                    },
                    value:config.filter && config.filter[field.key] && config.filter[field.key].match || ""
                })
            ));

            if (field.type === "date" || field.format === "date"){
                let fromInput,toInput;
                let fromRow,toRow;

                $(".dateselectbox",{parent:cell},
                    fromRow = $(".datebox",$("label",translations.from),fromInput = $("input",{type:"text"})),
                    toRow = $(".datebox",$("label",translations.to),toInput = $("input",{type:"text"})),
                );

                let fromValue = config.filter && config.filter[field.key] && config.filter[field.key].from;
                let toValue = config.filter && config.filter[field.key] && config.filter[field.key].to;

                function setValue(type){
                    return (value)=>{
                        config.filter = config.filter || {};
                        config.filter[field.key] = config.filter[field.key] || {};
                        config.filter[field.key][type] = value;

                        let d = new Date(value);
                        if (isNaN(d.getTime())){
                            config.filter[field.key][type + "Date"] = undefined;
                        }else{
                            config.filter[field.key][type + "Date"] = d;
                        }

                        renderList(config,itemList);
                    }
                }

                fromRow.appendChild(generateDatePicker(fromInput,fromValue,true,setValue("from")));
                toRow.appendChild(generateDatePicker(toInput,toValue,true, setValue("to")));
            }

            if (field.enumFilter){
                parent.classList.add("enumfilter");
                let filterBox = $(".filterbox",{parent:cell});
                let keyList = $(".keylist",{parent:filterBox});
                api.distinct(config.profile,field.key,config.preFilterName).then(data=>{
                    data.forEach(item=>{
                        let key = item[field.key];
                        if (!key) return;
                        let isChecked = config.filter && config.filter[field.key] && config.filter[field.key].values && config.filter[field.key].values.includes(""+key);
                        let sKey = "" + key;
                        if (field.translate){
                            sKey = translations[cleanString(key)] || key;
                        }
                        $(".key" + (isChecked?".checked":""),{
                            parent:keyList,
                            value:key,
                            onClick:(e)=>{
                                e.target.classList.toggle("checked");
                                let values = [];
                                keyList.querySelectorAll(".key.checked").forEach(key=>{
                                    values.push(key.value);
                                });
                                config.filter = config.filter || {};
                                config.filter[field.key] = config.filter[field.key] || {};
                                config.filter[field.key].values = values;
                                renderList(config,itemList);

                            }},sKey);
                    });
                });
            }

            if (field.size){
                let size = field.size;
                if (first) size += 32;
                cell.style.width = size + "px";
            }
            first = false;

        });
    }

    function renderList(config,parent){
        return new Promise(async next=>{
            currentItemList = parent;
            let data = await api.list(config.profile);

            page.waiting(false);
            parent.innerHTML = "";

            if (config.preFilter){
                data = data.filter(config.preFilter);
            }

            if (data && data.length){
                if (config.sort){
                    sortList(data,config.sort);
                    if (config.sortOrder === "desc") data.reverse();
                }
                if (config.filter){

                    let filterKeys = Object.keys(config.filter);
                    filterKeys.forEach(key=> {
                        let filter = config.filter[key];
                        filterButtons[key].classList.toggle("active",!!(filter.match || filter.from || filter.to || (filter.values && filter.values.length)));
                    });

                    data = data.filter(item=>{
                        let passed = true;
                        filterKeys.forEach(key=>{
                            let filter = config.filter[key];
                            let filterValue = "" + (item[key + "_resolved"] || item[key]);
                            if (filter.match){
                                // this is a free text search -> filter on the display value (=resolved value)
                                if (filterValue.toLowerCase().indexOf(filter.match)<0) passed = false;
                            }
                            if (filter.values && filter.values.length){
                                // this is a filter with pre-defined values (coming from an enum select list) -> filter on the actual value
                                if (!filter.values.includes(item[key])) passed = false;
                            }

                            if (filter.from || filter.to){
                                // this is a date filter
                                let date = new Date(filterValue);
                                if (filter.fromDate){
                                    if (date<filter.fromDate) passed = false;
                                }
                                if (filter.toDate){
                                    if (date>filter.toDate) passed = false;
                                }
                            }
                        });
                        return passed;
                    });
                }

                let startPage = config.page || 0;
                let startIndex = startPage * 100;
                if (startIndex >= data.length){
                    config.page = 0;
                    startIndex = 0;
                }
                let endIndex = startIndex + 100;
                let max = Math.min(data.length,endIndex);

                for (let i=startIndex;i<max;i++){
                    let item = data[i];
                    let first = true;
                    let row = $(".row",
                        {parent:parent,onClick:(e)=>{
                                let row = e.target.closest(".row");
                                parent.querySelectorAll(".row").forEach(row=>{
                                    row.classList.remove("selected");
                                });
                                row.classList.add("selected");
                                generateForm(config,item);
                            }},
                        deleteButton(config,item.id),
                        config.fields.map(field=>{
                            if (!field.isPrimary) return;
                            let cell;
                            let value = "" + (item[field.key] || "");
                            if (!value && field.keyAlt){
                                value = "" + (item[field.keyAlt] || "");
                            }
                            if (field.type === "fk"){
                                let profile = field.target.split(".")[0];
                                let fieldName = field.target.split(".")[1] || "id";
                                api.getById(profile,value).then(result=>{
                                    if (result){
                                        cell.innerHTML = result[fieldName];
                                        item[field.key + "_resolved"] = result[fieldName];
                                    }
                                });
                                value = "";
                            }

                            if (field.type === "boolean"){
                                value = value === "true"?translations.yes:translations.no;
                                item[field.key + "_resolved"] = value;
                            }

                            if(field.translate){
                                value = translations[cleanString(value)] || value;
                                item[field.key + "_resolved"] = value;
                            }

                            if (field.format === "date"){
                                value = formatDate(value);
                                item[field.key + "_resolved"] = value;
                            }

                            if (field.format === "id" && value && value.indexOf("-")>0){
                                value = value.split("-")[0];
                            }

                            let className = field.className || "";
                            if (className) className = "." + className;
                            cell = $("div.cell"+className,value);
                            if (field.size){
                                cell.style.width = field.size + "px";
                            }else{
                                if (first) cell.style.width = (150-24) + "px";
                            }
                            first = false;
                            return cell;
                        })
                    );

                    if (config.openDetailId && (config.openDetailId === item.id || config.openDetailId === item.report_id)){
                        row.classList.add("selected");
                    }
                }
                if (currentNav){
                    currentNav.innerHTML = "";

                    let info = $(".info",{parent:currentNav},(startIndex+1) + "-" + (max) + " of " + data.length);

                    let count = data.length;
                    let pages = Math.ceil(count/100);

                    $(".button.prev" + (startPage<1?".inactive":""),{parent:currentNav,onClick:()=>{
                            config.page = startPage-1;
                            renderList(config,parent);
                        }});

                    $(".button.next" + (startPage>=pages-1?".inactive":""),{parent:currentNav,onClick:()=>{
                            config.page = startPage+1;
                            renderList(config,parent);
                        }});

                    let buttons = currentNav.closest(".buttons");
                    if (buttons){
                        let filterButton = buttons.querySelector(".filterbutton");
                        if (filterButton) filterButton.classList.toggle("hidden",!(config.filter));
                    }



                }

                if (config.openDetailId){
                    let row = parent.querySelector(".row.selected");
                    if (row) row.scrollIntoView();

                    let item = data.find(item=>item.id === config.openDetailId || item.report_id == config.openDetailId);
                    if (item) generateForm(config,item);
                }

                next(data.length);
            }else{
                parent.appendChild($(".loadertext",translations.noData));
                if (currentNav) currentNav.innerHTML = "";
                next(0);
            }
        });
    }

    function generateForm(config,obj){
        let isNew = !obj;
        obj = obj || {};
        currentDataItem = obj;
        formContainer.innerHTML = "";
        formContainer.classList.remove("double");
        let title = !isNew?translations.edit + " " + config.name:translations.new + " " + (config.name || "incident");
        let form = $(".form.generic",{parent:formContainer},$("h3",title),$(".closebutton.light",{onClick:me.closeSidePanel}));

        if (config.detail && !isNew){
            config.detail(obj,form);
        }else{
            config.editors=[];
            config.fields.forEach(field=>{
                let show = !field.hideOnEdit;
                if (field.scope === "owner" && !user.isOwner()) show = false;
                if (show) config.editors.push(generateEditor(form,field,obj,config));
            });

            $(".errormessage",{parent:form},translations.fill_in_required_fields);

            let button;
            $(".buttons",{parent:form},button = $("button.primary.green",{onClick:()=>{
                    let obj = {};
                    let isValid = true;
                    form.classList.remove("inerror");
                    button.classList.remove("error");
                    config.editors.forEach(editor=>{
                        let value = editor.input?editor.input.value:editor.getValue();
                        if (value || typeof value === "boolean" || typeof value === "number") obj[editor.key] = value;

                        if (editor.input){
                            editor.input.classList.remove("error");
                        }

                        let field = config.fields.find(field=>field.key===editor.key);
                        if (field){
                            if (field.isRequired && !value){
                                isValid = false;
                                if (editor.input){
                                    editor.input.classList.add("error");
                                }

                            }
                            if (field.type === "map"){
                                let co = value.split(",");
                                obj.latitude = parseFloat(co[0]);
                                obj.longitude = parseFloat(co[1]);
                                if (isNaN(obj.latitude) || isNaN(obj.longitude)){
                                    obj.latitude = 0;
                                    obj.longitude = 0;
                                }
                            }
                        }
                    });
                    if (!isValid){
                        button.classList.add("error");
                        form.classList.add("inerror");
                        return;
                    }
                    if (config.postProcessing){
                        config.postProcessing(obj);
                    }
                    api.update(config.profile,obj).then(async result=>{

                        if (config.postUpdate) await config.postUpdate(obj,result);
                        me.refresh();
                        me.closeSidePanel();
                    });
                }},translations.save),$("button.primary.red.delete",{onClick:me.closeSidePanel},translations.cancel));
        }

        setTimeout(()=>{
            formContainer.classList.add("active");
        },20);
    }

    function generateEditor(parent,config,obj,formConfig){
        obj = obj || {};
        let editor= {
            key: config.key,
        };
        let value = obj[config.key];

        if (!value){
            if (config.type !== "boolean"){
                value = config.default;
                if (typeof value === "function") value = value(obj);

                console.log(value)
            }else{
                if (typeof value === "undefined" && typeof config.default === "boolean") value = config.default;
            }
        }
        let editorElm = $(".fieldset",{parent:parent},$("label",config.label));
        if (config.collapsed) editorElm.classList.add("collapsed");
        switch (config.type){
            case "select":
                let hasSelection = false;
                let otherValue = "";
                if (value &&  value.indexOf('(')>=0){
                    let p = value.indexOf("(");
                    otherValue = value.substring(p+1).replace(")","");
                    value = value.substring(0,p).trim();
                }

                let select;
                let otherInput;
                editor.onChange = [];

                editor.element = $(".select",{parent:editorElm}, select = $("select.capitalize",
                    config.values.map(val=>{
                        if (typeof val === "string"){
                            val={label:val,value:val};
                        }
                        if (val.value === value) hasSelection = true;
                        if (config.translate){
                            val.label = translations[cleanString(val.label)] || val.label;
                        }
                        return $("option",{value:val.value,selected:value===val.value},val.label);
                    })
                ));
                if (!hasSelection){
                    select.appendChild($("option",{value:(value || ""),selected:true},value || translations.pleaseSelect));
                }
                select.onchange = ()=>{
                    editor.onChange.forEach(callback=>{
                        callback();
                    });
                }

                if (config.child){
                    //let subSelect = $("select.capitalize",{parent:editorElm});
                    editor.onChange.push(()=>{
                        let value = select.value;
                        let childElement = formConfig.editors.find(editor=>editor.key===config.child);
                        let childSelect = childElement.element.querySelector("select");
                        if (childSelect){
                            let currentValue = childSelect.value;
                            childSelect.innerHTML = "";
                            let values = (config.childValues[value] || []).slice();
                            values.unshift("");
                            values.forEach(val=>{
                                let label = translations[cleanString(val)] || val;
                                childSelect.appendChild($("option",{value:val,selected:val===currentValue},label));
                            });
                        }
                    });
                    setTimeout(()=>{
                        select.onchange();
                    },100);
                }

                if (config.hasOtherValue){
                    otherInput = $("input",{parent:editorElm,value:otherValue,placeholder:translations.otherValue});
                    editor.onChange.push(()=>{
                        let value = (select.value || "").toLowerCase();
                        otherInput.style.display = value==="other"?"block":"none";
                    });
                    setTimeout(()=>{
                        select.onchange();
                    },100);
                }

                if (config.onChange){
                    editor.onChange.push(()=>{
                        config.onChange(select.value,formConfig);
                    });
                    setTimeout(()=>{
                        select.onchange();
                    },100);
                }

                editor.getValue = ()=>{
                    let value = select.value || "";
                    if (otherInput && value.toLowerCase() === "other"){
                        value += " (" +  otherInput.value + ")";
                    }
                    return value;
                }
                break;
            case "multiselect":
                let values = [];
                let other = "";
                if (value){
                    values = value.split(", ");
                    let otherIndex = values.findIndex(val=>val.startsWith("other"));
                    if (otherIndex>=0){
                        other = values[otherIndex];
                        let p = other.indexOf("(");
                        if (p>0){
                            other = other.substring(p+1).replace(")","");
                        }
                        values[otherIndex] = "other";
                    }
                }

                editor.element = $(".multiselect",{parent:editorElm},config.values.map(val=>{
                    if (typeof val === "string"){
                        val={label:val,value:val};
                    }
                    let children;
                    if (config.childValues){
                        let childValues = config.childValues[val.value];
                        if (childValues && childValues.length){
                            children = $(".children",childValues.map(child=>{
                                let label = translations[cleanString(child)] || child;
                                let input = child==="other"?$("input",{onClick:()=>{}}):undefined;
                                return $(".cb",{
                                    onClick:(e,elm)=>{
                                        elm.classList.toggle("active");
                                    },
                                    onUp:(e,elm)=>{
                                        if (input && elm.classList.contains("active")){
                                            input.focus();
                                        }
                                    },
                                    value:child
                                    },label,input);
                            }));
                        }
                    }
                    let label = translations[cleanString(val.label)] || val.label;
                    let input = val.label==="other"?$("input",{onClick:()=>{},value:other}):undefined;
                    let isChecked = values.includes(val.value);
                    return $(".cbfield",$(".cb" + (isChecked?".active":""),{
                        onClick:(e,elm)=>{
                            elm.classList.toggle("active");
                        },
                        onUp:(e,elm)=>{
                            if (input && elm.classList.contains("active")){
                                input.focus();
                            }
                        },
                        value:val.value
                        },label,input),children);
                }));

                editor.getValue = ()=>{
                    let values = [];
                    editor.element.querySelectorAll(".cb.active").forEach(cb=>{
                        values.push(cb.value);
                    });
                    return values.join(", ");
                }

                break;
            case "fk":
                editor.input = $("select.capitalize",{parent:editorElm},$("option",{value:""},""));
                let profile = config.target.split(".")[0];
                let field = config.target.split(".")[1] || "id";
                api.distinct(profile,field).then(data=>{
                    data.forEach(item=>{
                        editor.input.appendChild($("option",{value:item.id,selected:value===item.id},item[field]));
                    });
                });
                break;
            case "textarea":
                editor.input = $("textarea",{parent:editorElm,value:value || ""});
                break;
            case "boolean":
                let checkbox = $(".yesno",{parent:editorElm,onClick:()=>{
                        checkbox.classList.toggle("active");
                        onInput();
                    }},$("span",translations.no),$("span",translations.yes));

                let onInput = ()=>{};
                if (config.children){
                    onInput = ()=>{
                        let value =  checkbox.classList.contains("active");
                        config.children.forEach(child=>{
                            let childEditor = formConfig.editors.find(editor=>editor.key===child);
                            if (childEditor && childEditor.input){
                                childEditor.input.closest(".fieldset").classList.toggle("collapsed",!value);
                            }
                        });
                    }
                }

                editorElm.classList.add("single");
                if (value) checkbox.classList.add("active");
                if (onInput()){
                    setTimeout(()=>{
                        onInput();
                    },100);
                }
                editor.getValue = ()=>{
                    return checkbox.classList.contains("active");
                }
                break;
            case "collection":
                let fields = config.fields;
                let collection = $(".collection",{parent:editorElm});
                let sublist = $(".sublist",{parent:collection});
                $("button.icon.add.nobackground",{parent:collection,onClick:()=>{
                        let row = $(".subrow",{parent:sublist});
                        fields.forEach(field=>{generateEditor(row,field)});
                    }});
                break;
            case "hidden":
                editor.input = $("input",{parent:editorElm,value:value || "",type:"hidden"});
                editorElm.classList.add("hidden");
                break;
            case "password":
                editor.input = $("input",{type:"password",autocomplete:"off",parent:editorElm,value:value || ""});
                break;
            case "map":
                let map = $(".map",{id:"mapView",parent:editorElm});
                map.style.height = "200px";
                editor.input = $("input",{parent:editorElm,value:value || ""});
                showMap(editorElm,-4.6, 25.2,true,editor.input);
                break;
            case "text":
                if (config.format === "date"){
                    if (value) value = formatDate(value);
                }
                editor.input = $("input",{parent:editorElm,value:value || "",type:"text"});
                break;
            case "date":
                if (value) value = formatDate(value);
                editor.input = $("input",{parent:editorElm,value:value || "",type:"text"});
                editorElm.appendChild(generateDatePicker(editor.input,value));
                break;
            default:
                editor.input = $("input",{parent:editorElm,value:value || ""});
        }
        return editor;
    }

    function showMap(parent,lat,lng,dragMarker,coElm){
        mapboxgl.accessToken = 'pk.eyJ1IjoiaXBpc3Jlc2VhcmNoIiwiYSI6IklBazVQTWcifQ.K13FKWN_xlKPJFj9XjkmbQ';
        const map = new mapboxgl.Map({
            container: 'mapView', // container ID
            style: 'mapbox://styles/mapbox/streets-v12', // style URL
            center: [lng, lat], // starting position [lng, lat]
            zoom: 9 // starting zoom
        });

        const marker = new mapboxgl.Marker({draggable: dragMarker})
            .setLngLat([lng, lat])
            .addTo(map);

        marker.on('dragend', ()=>{
            const co = marker.getLngLat();

            coElm.value = co.lat.toFixed(7) + ", " + co.lng.toFixed(7);
            //coordinates.style.display = 'block';
            //coordinates.innerHTML = `Longitude: ${lngLat.lng}<br />Latitude: ${lngLat.lat}`;
        });


    }

    function deleteButton(config,key){
        return $(".button.icon.delete" + (config.canDelete?".active":""),{title:"Delete",onClick:(e)=>{
            e.stopPropagation();
            let row = e.target.closest(".row");
                row.classList.add("removing");
                let confirm = $(".confirm",{onClick:(e)=>{
                    e.stopPropagation();
                        }},translations.deleteItemQuestion,
                    $("button.small",{
                        onClick:()=>{
                            row.removeChild(confirm);
                            row.classList.add("loading");
                            api.delete(config.profile,key).then(result=>{
                                if (result.status === "ok"){
                                    row.parentNode.removeChild(row);
                                }else{
                                    row.classList.remove("loading");
                                    row.classList.remove("removing");
                                    row.classList.add("error");
                                }
                            })
                        }
                    },translations.yes),
                    $("button.small",{
                        onClick:()=>{
                            row.classList.remove("removing");
                            row.removeChild(confirm);
                        }
                    },translations.no));
                row.appendChild(confirm)
                setTimeout(()=>{
                    confirm.classList.add("active");
                },10);
            }});
    }

    function sortList(data,sort){
        data.sort((a,b)=>{
            let valueA = a[sort + "_resolved"] || a[sort] || "";
            let valueB = b[sort + "_resolved"] || b[sort] || "";
            if (typeof valueA === "string") valueA = valueA.toLowerCase();
            if (typeof valueB === "string") valueB = valueB.toLowerCase();
            if (valueA < valueB) return -1;
            if (valueA > valueB) return 1;
            return 0;
        });
    }

    async function exportData(config){
        let data = await api.dump(config.profile,config.preFilterName);
        await api.resolveAll(config,data);

        let organisations = await api.distinct("organisations","name");
        let users = await api.distinct("users","fullname");

        let orgFields = ["assigned","organisation"];
        let userFields = ["assignedBy","archivedBy","acceptedBy","rejectedBy","closedBy"];
        let dateFields = ["assignedOn","archivedOn","acceptedOn","rejectedOn","closedOn","date","lastModified"];
        let countComments = config.profile.indexOf("incidents")>=0;

        let comments = [];
        if (countComments){
            comments = await api.get("comments?fields=incident");
        }



        if (config.filter){
            let filterKeys = Object.keys(config.filter);
            data = data.filter(item=>{
                let passed = true;
                filterKeys.forEach(key=>{
                    let filter = config.filter[key];
                    let filterValue = "" + (item[key + "_resolved"] || item[key]);
                    if (filter.match){
                        if (filterValue.toLowerCase().indexOf(filter.match)<0) passed = false;
                    }
                    if (filter.values && filter.values.length){
                        if (!filter.values.includes(filterValue)) passed = false;
                    }
                    if (filter.from || filter.to){
                        let date = new Date(filterValue);
                        if (filter.fromDate){
                            if (date<filter.fromDate) passed = false;
                        }
                        if (filter.toDate){
                            if (date>filter.toDate) passed = false;
                        }
                    }
                });
                return passed;
            });
        }

        // consolidate all keys
        let keyMap = {};
        data.forEach(item=>{
            if (countComments){
                item.commentCount = 0;
            }
            Object.keys(item).forEach(key=>{
                if (key.indexOf("_resolved")>=0) return;
                keyMap[key] = true;
                if (countComments && key.startsWith("platform_comment_") && item[key]){
                    item.commentCount++;
                }
            });

            orgFields.forEach(field=>{
                let value = item[field];
                if (value){
                    let org = organisations.find(org=>org.id===value);
                    if (org) item[field] = org.name;
                }
            });
            userFields.forEach(field=>{
                let value = item[field];
                if (value){
                    let user = users.find(user=>user.id===value);
                    if (user) item[field] = user.fullname;
                }
            });
            dateFields.forEach(field=>{
                let value = item[field];
                if (value && typeof value === "number"){
                    item[field] = formatDate(value);
                }
            });

            if (countComments){
                let id = item.report_id || item.id;
                let itemComments = comments.filter(comment=>comment.incident == id);
                item.commentCount += itemComments.length;
            }
        });

        if (countComments){
            for (let i=1;i<11;i++){
                delete keyMap["platform_comment_"+i];
            }
            keyMap["commentCount"] = true;
        }

        delete keyMap["info_id"];

        let keys =  Object.keys(keyMap);
        keys.sort();

        let fileName = config.profile;
        if (config.preFilterName) fileName += "_" + config.preFilterName;
        fileName += ".xlsx";

        let module = await import("./excel.js");
        let Excel = module.default;
        Excel.export(data,keys,fileName);

    }

    function generateDatePicker(input,value,allowEmpty,onChange){
        const datepicker = new NativeDatepicker({
            initialValue: intDate(value),
            onChange: newValue => {
                input.value = formatDate(newValue);
                input.onchange();
            }
        });
        input.onchange = event => {
            input.classList.remove('error');
            let d = new Date(input.value);
            if (isNaN(d.getTime())){
                if (input.value || !allowEmpty) input.classList.add('error');
            }else{
                input.value = formatDate(d);
                datepicker.setValue(intDate(input.value));
            }
            if (onChange) onChange(input.value);
        };
        return datepicker.element;
    }

    return me;
})();
export default MasterDetail;