import api from "./api.js";
import $ from "./util/dom.js";
import translations from "./translations.js";
import Settings from "./settings.js";

let User=(()=>{
    let me = {};
    let currentUser = null;

    me.login = async (username,password)=>{
        let result = await api.login(username,password);
        if (result && result.user){
            currentUser = result.user;
            if (currentUser.token){
                localStorage.setItem("token",currentUser.token);
            }
            return {
                success: true,
                needsPasswordReset:result.needsPasswordReset
            };
        }
        return {success:false};
    }

    me.logout = ()=>{
        localStorage.removeItem("token");
        window.location.hash = "";
        window.location.reload();
    }


    me.getCurrentUser = ()=>{
        return currentUser;
    }

    me.isLoggedIn = async ()=>{
        let queryParams = new URLSearchParams(window.location.search);
        let userToken = queryParams.get("token");
        if (currentUser && currentUser.id) return true;
        let token = localStorage.getItem("token");
        if (!token && !userToken) return false;

        let result = await api.checkToken(token);
        if (result && result.user){
            currentUser = result.user;
            Settings.setLanguage(currentUser.language);
            Settings.setTheme(currentUser.theme || "dark");
            if (currentUser.theme==="light") document.body.classList.add("light");
            return true;
        }else{
            localStorage.removeItem("token");
            return false;
        }
    }

    me.getUserId = ()=>{
        return (currentUser && currentUser.id) || "";
    }

    me.getUserOrganisation = ()=>{
        return (currentUser && currentUser.organisation) || "";
    }

    me.getAccesToken = ()=>{
        return (currentUser && currentUser.token) || 0;
    }

    me.getSessionId = ()=>{
        return (currentUser && currentUser.sessionId) || 0;
    }

    me.isAdmin = ()=>{
        return currentUser && (currentUser.role==="admin" || currentUser.role==="owner");
    }

    me.isOwner = ()=>{
        return currentUser && currentUser.role==="owner";
    }

    me.renderUserMenu = (container)=>{
        let menu;
        let lanbox,themebox, emailbox;
        let lan = Settings.getLanguage();
        let theme = Settings.getTheme();
        container.appendChild(
            menu = $(".usermenu",
                $(".username",{onClick:()=>{
                        menu.classList.toggle("active");
                    }},currentUser.fullname),
                $(".menu",
                    $(".menuitem",$("label",translations.language),
                        lanbox = $(".yesno",{onClick:async ()=>{
                                lanbox.classList.toggle("active");
                                lan = lanbox.classList.contains("active") ? "en" : "fr";
                                await api.update("users",{id:currentUser.id,language:lan});
                                Settings.setLanguage(lan);
                                setTimeout(()=>window.location.reload(),200);
                            }},$("span","FR"),$("span","EN"))
                    ),
                    $(".menuitem",$("label",translations.theme),
                        themebox = $(".yesno",{onClick:async ()=>{
                                themebox.classList.toggle("active");
                                theme = themebox.classList.contains("active") ? "dark" : "light";
                                await api.update("users",{id:currentUser.id,theme:theme});
                                Settings.setTheme(theme);
                                document.body.classList.toggle("light",theme==="light");
                                //console.error("Theme",theme);
                                //setTimeout(()=>window.location.reload(),200);
                            }},$("span",translations.light),$("span",translations.dark))
                    ),
                    $(".menuitem",$("label",translations.emailnotificationsShort),
                        emailbox = $(".yesno",{onClick:async ()=>{
                                emailbox.classList.toggle("active");
                                let value = themebox.classList.contains("active");
                                await api.update("users",{id:currentUser.id,emailnotifications:value});
                            }},$("span",translations.yes),$("span",translations.no))
                    ),
                    $(".menuitem",$("button.primary.red",{onClick:me.logout},translations.logout))
                )
            )
        );
        if (lan==="en") lanbox.classList.add("active");
        if (theme==="dark") themebox.classList.add("active");
    }

    return me;
})();
export default User;