import user from "../user.js";
import api from "../api.js";
import translations from "../translations.js";
import logger from "../logger.js";
import {formatDate} from "../util.js";
import Settings from "../settings.js";
import NotificationService from "./notificationService.js";

let IncidentService = (()=>{
    let me = {};

    me.getComments = async function(data){
        let comments=[];
        for (let i=1;i<=10;i++){
            let comment = data["platform_comment_"+i];
            if (comment){
                let c={}
                let p = comment.indexOf(" added ");
                if (p>=0){
                    c.user=comment.substring(0,p);
                    comment = comment.substring(p+7);
                }
                p = comment.lastIndexOf(" at ");
                if (p>=0){
                    c.date=comment.substring(p+4);
                    comment = comment.substring(0,p);
                    let d = new Date(c.date);
                    if (isDateValid(d)) c.date = formatDate(d);
                }
                c.comment=comment;
                comments.push(c);
            }
        }

        let k_Comments = await(api.list("comments",{incident:data.report_id || data.id}));
        k_Comments.forEach(comment=>{
            if (typeof comment.date === "number") comment.date = formatDate(comment.date);
            if (comment.user && comment.user.length>8){
                let user = api.resolve("users",comment.user);
                if (user) comment.user = user.fullname;
            }
            comments.push(comment);
        });

        comments.sort((a,b)=>a.date>b.date?-1:1);
        return comments;
    }

    me.postComment = (comment,incident,isAuto)=>{
        return new Promise((next)=>{
            let data ={
                comment:comment,
                incident:incident.report_id || incident.id,
                user:user.getUserId(),
                date:new Date().getTime(),
                language:Settings.getLanguage()
            }
            if (!isAuto){
                logger.log("comment",incident.report_id || incident.id);
            }
            api.update("comments",data).then(next);
        });
    }

    me.getActions = async function(data){
        let actions=[];
        let k = await(api.list("actions",{incident:data.report_id || data.id}));
        k.forEach(action=>{
            if (typeof action.date === "number") action.date = formatDate(action.date);
            if (action.user && action.user.length>8){
                let user = api.resolve("users",action.user);
                if (user) action.user = user.fullname;
            }
            actions.push(action);
        });

        actions.sort((a,b)=>a.date>b.date?-1:1);
        return actions;
    }

    me.postAction = (action,incident)=>{
        return new Promise((next)=>{
            let data = action;
            data.incident = incident.report_id || incident.id;
            data.user = user.getUserId();
            data.created = new Date().getTime();
            data.language = Settings.getLanguage();
            data.id = action.id;
            logger.log("action",data.incident);
            api.update("actions",data).then(next);
        });
    }

    me.deleteAction = (action)=>{
        return new Promise((next)=>{
            api.delete("actions",action.id).then(next);
        });
    }


    me.assignToCSO = async(incident,organisation)=>{
        let incidentID = incident.report_id || incident.id;

        let data ={
            id:incident.info_id,
            incident:incidentID,
            assigned:organisation,
            assignedBy:user.getUserId(),
            assignedOn:new Date().getTime(),
            status:"Assigné"
        }
        let result = await api.update("incidents_info",data);
        let orgName = api.resolve("organisations",organisation).name;
        api.clearCache("incidents_assigned");
        await me.postComment(translations.assignedTo + " " + orgName,incident,true);

        await NotificationService.send(NotificationService.assign,organisation,data);

        logger.log("assign",incidentID,organisation);
        return result;
    }


    me.archive = async (incident,status,reason)=>{
        let incidentID = incident.report_id || incident.id;

        let data ={
            id:incident.info_id,
            incident:incidentID,
            archived:!!status,
            archivedBy:user.getUserId(),
            archivedOn:new Date().getTime(),
            archivedReason:reason,
            status:translations.archived
        }
        await api.update("incidents_info",data);
        await me.postComment(translations.archived + ": " + reason,incident,true);
        logger.log("archive",incidentID);
    }

    me.reject = async(incident,reason)=>{
        let incidentID = incident.report_id || incident.id;
        let data ={
            id:incident.info_id,
            incident:incidentID,
            assigned:false,
            rejected:true,
            accepted:false,
            closed: false,
            rejectedBy:user.getUserId(),
            rejectedOn:new Date().getTime(),
            rejectReason:reason,
            status:translations.rejected
        }
        await api.update("incidents_info",data);
        await me.postComment(translations.rejected + ": " + reason,incident,true);
        logger.log("reject",incidentID);
    }

    me.accept = async (incident)=>{
        let incidentID = incident.report_id || incident.id;
        let data ={
            id:incident.info_id,
            incident:incidentID,
            accepted:true,
            acceptedBy:user.getUserId(),
            acceptedOn:new Date().getTime(),
            rejected: false,
            closed: false,
            status:"En cours de suivi",
            category: '',
            subcategory: '',
        }
        //console.log(data);
        await api.update("incidents_info",data);
        await me.postComment(translations.accepted,incident,true);
        logger.log("accept",incidentID);

    }

    me.close = async (incident,status)=>{
        let incidentID = incident.report_id || incident.id;
        let data ={
            id:incident.info_id,
            incident:incidentID,
            closed:true,
            closedBy:user.getUserId(),
            closedOn:new Date().getTime(),
            status:status
        }
        await api.update("incidents_info",data);
        await me.postComment(translations.closed,incident,true);
        logger.log("close",incidentID);
    }

    me.reopen = async (incident)=>{
        let incidentID = incident.report_id || incident.id;
        let data ={
            id:incident.info_id,
            incident:incidentID,
            closed:false,
            closedBy:"",
            closedOn:"",
            status:"En cours de suivi"
        }
        await api.update("incidents_info",data);
        await me.postComment(translations.reopened,incident,true);
        logger.log("reopen",incidentID);
    }

    me.delete = async (incident)=>{
        let incidentID = incident.report_id || incident.id;
        let data ={
            id:incident.info_id,
            incident:incidentID,
            deleted:true,
            deletedBy:user.getUserId(),
            deletedOn:new Date().getTime()
        }
        await api.update("incidents_info",data);
        await api.delete("incidents",incidentID);
        await me.postComment(translations.deleted,incident,true);
        logger.log("delete",incidentID);
    }

    me.mergeInfoWithOriginal = async (incident)=>{
        if (incident.id && typeof incident.id === "string" && incident.id.indexOf("-")>0){
            await api.getById("incidents",incident.id).then(result=>{
                if (result){
                    let newKeys = Object.keys(result);
                    let oldKeys = Object.keys(incident);
                    newKeys.forEach(key=>{
                        if (!oldKeys.includes(key)){
                            incident[key] = result[key];
                        }
                    });
                }
            });
        }
    }

    me.isAssigned = (incident)=>{
        return incident.status === translations.assigned || incident.status === "Assigné" || incident.status === "Assigned";
    }

    me.isInprogress = (incident)=>{
        return incident.status === translations.followedUp || incident.status === "En cours de suivi" || incident.status === "In process of follow up";
    }

    me.isClosed = (incident)=>{
        return !!incident.closed;
    }

    me.isComplete = async (incidentInfo)=>{

        if (incidentInfo.incident && typeof incidentInfo.incident === "string" && incidentInfo.incident.indexOf("-")>0){
            await api.getById("incidents",incidentInfo.incident).then(result=>{
                if (result){
                    let newKeys = Object.keys(result);
                    let oldKeys = Object.keys(incidentInfo);
                    newKeys.forEach(key=>{
                        if (!oldKeys.includes(key)){
                            incidentInfo[key] = result[key];
                        }
                    });
                }
            });

        }
        let requiredFields = [
            "date","title","description","category","subcategory",
            "province","territoire","sector","group"
        ];
        let complete = true;
        requiredFields.forEach(field=>{
            if (!incidentInfo[field]) complete = false;
        });
        return complete;
    }

    me.getGeoLocationFromData =  (incident)=>{
        //let district = incident.district;
        //let location = dataStructure.regionLocations[district];
        //if (location) return location;
        //let region = incident.region;
        //location = dataStructure.regionLocations[region];
        //if (location) return location;
        return {lat:0,lng:0};
    }


    function isDateValid(dateStr) {
        return !isNaN(new Date(dateStr));
    }

    return me;
})();

export default IncidentService;