import user from "./user.js";
import EventBus from "./lib/eventBus.js";
import translations from "./translations.js";
import {cleanString, formatDate} from "./util.js";

let Api = (()=>{
    let me = {};
    //let apiUrl = "http://localhost:8080/api/";
    //if (window.location.hostname.indexOf("kufatilia")>=0) apiUrl = "https://kufatilia.ipisresearch.be/api/";

    let apiUrl = "https://kufatilia.ipisresearch.be/api/";
    //if (window.location.hostname.indexOf("localhost")>=0) apiUrl = "https://kufatilia.ipisresearch.be/dev/";
    if (window.location.pathname.indexOf("/test/")>=0) apiUrl = "https://kufatilia.ipisresearch.be/dev/";


    let dataCache = {
        distinct: {},
        dump: {},
        resolved : {}
    };

    me.list = async (profile,filter)=>{
        if (dataCache[profile]) return filterList(dataCache[profile],filter);
        let url = apiUrl + profile;
        let json = await getJson(url);
        dataCache[profile] = json;
        return filterList(json,filter);
    }

    me.dump = async (profile,filter)=>{
        let url = apiUrl + profile + "?dump=true&filter=" + filter;
        return getJson(url);
    }

    me.get = async (profile)=>{
        let url = apiUrl + profile;
        return getJson(url);
    }

    me.distinct = async (profile,field,filterName)=>{
        let key = profile + "_" + field;
        if (filterName) key += "_" + filterName;
        let url = apiUrl + profile + "?distinct=" + field + "&filter=" + filterName;

        let orgId;
        if(filterName==="complete" || filterName==="new"){
            orgId = user.getUserOrganisation();
            url += "&org=" + orgId;
            key += "_" + orgId;
        }
        if (dataCache.distinct[key]) return dataCache.distinct[key];
        let json = await getJson(url);
        dataCache.distinct[key] = json;
        return json;
    }

    me.getById = async (profile,id,forceRefresh)=>{
        if (!forceRefresh){
            if (dataCache[profile + "_" + id]) return dataCache[profile + "_" + id];
            if (dataCache[profile]) return dataCache[profile].find(item=>item.id===id);
        }
        let url = apiUrl + profile + "/" + id;
        let json = await getJson(url);
        dataCache[profile + "_" + id] = json;
        return json;
    }

    me.resolve = (profile,id)=>{
        if (dataCache[profile + "_" + id]) return dataCache[profile + "_" + id];
        if (dataCache[profile]) return dataCache[profile].find(item=>item.id===id);
    }

    me.resolveAll = (config,data)=>{
        return new Promise(async next=>{
            if (dataCache.resolved[config.profile] && !data){
                next();
                return
            }

            let useCache = false;
            if (!data){
                data = await me.list(config.profile);
                useCache = true;
            }
            if (data && data.length){
                data.forEach(item=>{
                    config.fields.forEach(field=>{
                        if (!field.isPrimary) return;

                        let value = "" + (item[field.key] || "");
                        if (!value && field.keyAlt){
                            value = "" + (item[field.keyAlt] || "");
                        }

                        if (field.type === "fk"){
                            let profile = field.target.split(".")[0];
                            let fieldName = field.target.split(".")[1] || "id";

                            let itemData;
                            if (dataCache[profile + "_" + value]) itemData = dataCache[profile + "_" + value];
                            if (dataCache[profile]) itemData = dataCache[profile].find(item=>item.id===value);

                            if (itemData){
                                item[field.key + "_resolved"] = itemData[fieldName];
                            }
                        }

                        if (field.type === "boolean"){
                            value = value === "true"?translations.yes:translations.no;
                            item[field.key + "_resolved"] = value;
                        }

                        if(field.translate){
                            value = translations[cleanString(value)] || value;
                            item[field.key + "_resolved"] = value;
                        }

                        if (field.format === "date"){
                            value = formatDate(value);
                            item[field.key + "_resolved"] = value;
                        }
                    });
                });

                if (useCache) dataCache.resolved[config.profile] = true;
            }
            next();
        });
    }

    me.getIncident = async(id)=>{
        let profile = "incidents_external";
        if (isNaN(id)) profile = "incidents";
        let url = apiUrl +  profile + "/" + id;
        let json = await getJson(url);
        return json || {};
    }

    me.update = async (profile,obj)=>{
        let url = apiUrl + profile;
        let response = await fetch(url,{
            method: "POST",
            body: JSON.stringify(obj),
            headers: {
                Authorization: 'Bearer ' + user.getSessionId(),
            }
        });
        let json = await response.json();

        dataCache[profile + "_" + json.id] = json;
        if (dataCache[profile]){
            let existing = dataCache[profile].find(item=>item.id===json.id);
            if (existing){
                Object.assign(existing,json);
            }else{
                dataCache[profile].push(json);
            }
        }

        //console.error(profile,json);
        if (profile === "incidents_info" && json.incident){

            let dto = Object.assign({},json);
            delete dto.id;

            // update original incident
            let baseProfile = dataCache["incidents_external"];
            if (baseProfile){
                let existing = baseProfile.find(item=>item.report_id==dto.incident);
                if (existing){
                    Object.assign(existing,dto);
                }
            }

            // update assigned incident
            baseProfile = dataCache["incidents_assigned"];
            if (baseProfile){
                let existing = baseProfile.find(item=>item.incident==dto.incident);
                if (existing){
                    Object.assign(existing,dto);
                }
            }
        }

        EventBus.trigger("dataChanged",profile);

        return json;
    }

    me.updateCache = (profile,obj)=>{
        dataCache[profile + "_" + obj.id] = obj;
        if (dataCache[profile]){
            let existing = dataCache[profile].find(item=>item.id===obj.id);
            if (existing){
                Object.assign(existing,obj);
            }else{
                dataCache[profile].push(obj);
            }
        }
    }

    me.clearCache = (profile)=>{
        dataCache[profile] = undefined;
    }

    me.countCache = (profile,filter)=>{
        if (dataCache[profile]) return filterList(dataCache[profile],filter).length;
        return 0;
    }


    me.delete = async (profile,id)=>{
        //incidents_assigned
        let url = apiUrl + profile + "/" + id;
        let response = await fetch(url,{
            method: "DELETE",
            headers: {
                Authorization: 'Bearer ' + user.getSessionId(),
            }
        });
        let json = await response.json();
        dataCache[profile + "_" + id] = undefined;
        if (dataCache[profile]){
            dataCache[profile] = dataCache[profile].filter(item=>item.id!==id);
        }
        if (profile === "incidents"){
            let linkedProfiles = ["incidents_external","incidents_assigned"];
            linkedProfiles.forEach(linkedProfile=>{
                if (dataCache[linkedProfile]){
                    dataCache[linkedProfile] = dataCache[linkedProfile].filter(item=>item.incident!==id);
                    dataCache[linkedProfile] = dataCache[linkedProfile].filter(item=>item.id!==id);
                    dataCache[linkedProfile] = dataCache[linkedProfile].filter(item=>item.report_id!==id);
                }
                dataCache[linkedProfile + "_" + id] = undefined;
            });
        }
        return json;
    }

    me.getStats = async (orgId,forceRefresh)=>{
        console.log(dataCache);
        let url = apiUrl + "stats?org=" + orgId;
        let json = {};
        if (forceRefresh){
            json =  await getJson(url);
        }else{
            if (dataCache["incidents_external"]){
                json = {
                    incidents: me.countCache("incidents_external"),
                    archived: me.countCache("incidents_external",{archived:true}),
                    assigned: me.countCache("incidents_external",{assigned: item=>!!item.assigned && !item.archived})
                };
            }

            if (dataCache["incidents_assigned"]){
                json = Object.assign(json,{
                    closed: me.countCache("incidents_assigned",{closed:true}),
                    open: me.countCache("incidents_assigned",{open: item=>item.accepted && !item.closed}),
                    working: me.countCache("incidents_assigned",{working: item=>item.accepted && item.assigned === orgId && !item.closed}),
                    complete: me.countCache("incidents_assigned",{complete: item=>item.accepted && item.assigned === orgId && item.closed}),
                    new: me.countCache("incidents_assigned",{assigned: item=>!item.accepted && item.assigned === orgId}),
                });
            }

        }

        if (json && json.incidents){
            json.nonassigned = json.incidents - (json.assigned||0) - (json.archived||0)
        }
        return json;
    }

    me.getLogs = async ()=>{
        let url = apiUrl + "logs";
        let json = await getJson(url);
        return json;
    }

    me.login = async (username,password)=>{
        let url = apiUrl + "login";
        let response = await fetch(url,{
            method: "POST",
            body: JSON.stringify({username,password})
        });
        let json = await response.json();
        return json;
    }

    me.resetPassword = async (email)=>{
        let url = apiUrl + "resetpass";
        let response = await fetch(url,{
            method: "POST",
            body: JSON.stringify({email})
        });
        let json = await response.json();
        return json;
    }

    me.checkToken = async (token)=>{
        let url = apiUrl + "token";
        let response = await fetch(url,{
            method: "POST",
            body: JSON.stringify({token})
        });
        let json = await response.json();
        return json;
    }

    me.changePassword = async (password)=>{
        let url = apiUrl + "pass";
        let response = await fetch(url,{
            method: "POST",
            body: JSON.stringify({userId:user.getUserId(),password})
        });
        let json = await response.json();
        return json;
    }

    me.isDev = ()=>{
        return apiUrl.indexOf("/dev/")>=0;
    }

    function filterList(list,filter){
        if (!filter) return list;
        console.log("Filtering");
        return list.filter((item)=>{
            let pass = true;
            for (let key in filter){

                if (typeof filter[key] === "function"){
                    if (!filter[key](item)) pass = false;
                }else{
                    if (item[key]!=filter[key]) pass = false;
                }
            }

            return pass;
        });
    }

    function getJson(url){
        return new Promise((next)=>{
            fetch(url,{
                method: "GET",
                headers: {
                    Authorization: 'Bearer ' + user.getSessionId()
                }
            }).then(response=>{
                response.json().then(next);
            });
        });
    }

    me.sendNotification = async (type,recipient,data)=>{
        let url = apiUrl + "notification";
        let response = await fetch(url,{
            method: "POST",
            body: JSON.stringify({type,recipient,data}),
            headers: {
                Authorization: 'Bearer ' + user.getSessionId(),
            }
        });
        let json = await response.json();
        return json;
    }


    return me;
})();
export default Api;