import Api from "../api.js";

let NotificationService = (()=>{
    let me = {};

    me.send = async (type,recipient,data)=>{
        let result = await Api.sendNotification(type,recipient,data);
        console.error("Sending notification",result);
    }

    me.assign = "assign";

    return me;
})();

export default NotificationService;
