import translations from '../translations.js';
import Api from "../api.js";
import $ from "../util/dom.js";
let TranslationService = (()=>{
    let me = {};
    let cacheMap = {};
    let useTranslationService = false;

    me.translate = async (key,fromLan,toLan)=>{
        if (translations[key]){
            return translations[key];
        }
        let cache = cacheMap[fromLan + "_" + toLan];
        if (!cache){
            cache = {};
            cacheMap[fromLan + "_" + toLan] = cache;
        }
        if (cache[key]){
            console.log("Returning from cache");
            return cache[key];
        }

        if (useTranslationService){
            let result = await Api.translate(key,fromLan,toLan);
            cache[key] = result;
            return result;
        }else{
            return key;
        }

    }

    me.box = (text,src,target)=>{
        if (!useTranslationService){
            return text;
        }

        let elm;
        let button;
        if (src === target) return $(".text",text);
        let isTranslated = false;

        return $(".translatebox",elm = $(".text",text),button = $(".button",{onClick:()=>{
            if (isTranslated){
                elm.innerHTML = "";
                elm.innerText = text;
                isTranslated = false;
            }else{
                elm.innerHTML = "";
                elm.appendChild($(".spinner"));
                me.translate(text,src,target).then(result=>{
                    if (result.result){
                        elm.innerHTML = "";
                        elm.innerText = result.result;
                    }else{
                        elm.innerText = result;
                    }
                    isTranslated = true;
                    button.innerText = "Show original";
                });
            }
            }},"Translate to " + target.toUpperCase()));
    }

    return me;
})();

export default TranslationService;