import masterDetail from "../components/masterDetail.js";
import dataStructure from "../data.js";
import api from "../api.js";
import user from "../user.js";
import $ from "../util/dom.js";
import translations from "../translations.js";
import Logger from "../logger.js";
import {cleanString, formatDate} from "../util.js";
import logger from "../logger.js";
import incidentService from "../services/incidentService.js";
import nav from "../components/nav.js";
import IncidentsUI from "./incidents.js?v6";


let IncidentsAssigned = (()=>{
    let me = {};

    me.render = async(container,params,id)=>{
        let config = Object.assign({},dataStructure.incidentsAssigned);
        config.openDetailId = id;

        let userData = user.getCurrentUser();
        let orgId = userData?userData.organisation:"";

        if (params && params.filter){
            config.preFilterName = params.filter;
            if (params.filter==="open"){
                config.preFilter = item=>item.accepted && item.assigned && !item.closed;
            }

            if (params.filter==="closed"){
                config.preFilter =  item=>item.closed;
            }

            if (params.filter==="pending"){
                config.preFilter =  item=>(!item.accepted);
            }

            if (params.filter==="new"){
                config.preFilter = item=>!item.accepted && item.assigned === orgId;
            }

            if (params.filter==="working"){
                config.preFilter = item=>item.accepted && item.assigned === orgId && !item.closed;
            }

            if (params.filter==="complete"){
                config.preFilter = item=>item.accepted && item.assigned === orgId && item.closed;
            }




        }


        config.detail= async (item,formContainer)=>{

            window.location.hash = "incidents-cso/" + (item.report_id || item.id);

            formContainer.innerHTML = "<h3>Incident</h3>";
            formContainer.appendChild($(".closebutton.light",{onClick:masterDetail.closeSidePanel}));

            let reportData = await api.getIncident(item.report_id || item.id);
            let userData = user.getCurrentUser();
            let incidentInfo = item;
            if (item.info_id) {
                incidentInfo = await api.getById("incidents_info",item.info_id,true);
                if (!incidentInfo.status) incidentInfo.status = item.status;
            }
            let isMine = userData.organisation === reportData.assigned;
            let canEdit = isMine || user.isOwner();

            formContainer.appendChild(await IncidentsUI.renderDetail(reportData,canEdit));

            if (canEdit){

                let actionPanel = $(".incidentactions", {parent: formContainer}, $(".slidepanel"));

                if (incidentService.isAssigned(incidentInfo)){
                    actionPanel.appendChild($(".buttonsbar",
                        $("button.primary.green.arrow", {
                            onClick: () => {
                                incidentService.accept(item).then(() => {
                                    masterDetail.closeSidePanel();
                                    masterDetail.refresh();
                                    nav.animateUpdate("new", "working", 1);
                                });
                            }
                        }, translations.accept),
                        $("button.primary.red.delete", {
                            onClick: () => {
                                showRejectForm(actionPanel, item);
                            }
                        }, translations.reject),
                        $("button.primary.blue.arrow",
                            {
                                onClick: () => {
                                    masterDetail.closeSidePanel();
                                }
                            },
                            translations.close)
                    ));

                    let dConfig = Object.assign({},dataStructure.incidentsInfo);
                    if (user.isOwner()) masterDetail.double(dConfig);


                }else if (!incidentService.isClosed(incidentInfo)){
                    let dConfig = Object.assign({},dataStructure.incidentsInfo);

                    let isComplete = await incidentService.isComplete(incidentInfo);

                    if (isComplete){
                        dConfig.additionalButtons = ()=>{
                            return [
                                $("hr"),
                                $("button.primary.blue.check",{onClick:()=>{
                                        showCompleteForm(actionPanel,item);
                                    }},translations.mark_as_completed),
                            ];
                        }
                    }else{
                        dConfig.additionalButtons = ()=>{
                            return [
                                $("hr"),
                                $("button.primary.blue.check.disabled",translations.mark_as_completed,$(".hovermessage",translations.complete_data_first))
                            ];
                        }
                    }
                    masterDetail.double(dConfig);
                }else{

                    let buttons = [
                        $("button.primary.green.arrow",
                            {onClick: () => {
                                showReOpenForm(actionPanel,item);
                            }
                        }, translations.reopen)
                    ]

                    if (user.isOwner()){
                        buttons.push($("button.primary.red.delete", {
                            onClick: () => {
                                showDeleteForm(actionPanel, item);
                            }
                        }, translations.delete))
                    }

                    buttons.push( $("button.primary.blue.arrow",
                        {onClick: () => {
                                masterDetail.closeSidePanel();
                            }
                        }, translations.close)
                    )

                    actionPanel.appendChild($(".buttonsbar",buttons));

                    let dConfig = Object.assign({},dataStructure.incidentsInfo);
                    if (user.isOwner()) masterDetail.double(dConfig);
                }

            }else{
                if (incidentService.isClosed(incidentInfo)){
                    let dConfig = Object.assign({},dataStructure.incidentsInfo);
                    masterDetail.double(dConfig,true);
                }
            }


            /*let comments = await getComments(reportData);
            mapView.innerHTML = ""

            let lat = item.latitude;
            let lng = item.longitude;
            lat = parseFloat(lat);
            lng = parseFloat(lng);

            if (lng && lat){
                coElm.innerHTML = lat + ", " + lng;
                showMap(mapView,lat,lng,true,coElm);
            }

            $("div",{parent:detailElm},
                $(".location",item.location),
                $("h4",translations.information),
                $("table",Object.keys(reportData).map(key=>{
                    if (ignoreKeys.indexOf(key)>=0) return;
                    if (key.startsWith("platform_comment_")) return;
                    let value = reportData[key];
                    if (key==="report_date") value = formatDate(value);

                    let td = $("td",""+value);
                    if (resolveKeys[key]){
                        value = resolveKeys[key](value);
                        if (value && value.then){
                            value.then(value=>{
                                td.innerText = value;
                            });
                        }else{
                            td.innerText = value;
                        }
                    }

                    if (translateKeys.indexOf(key)>=0){
                        let translated = translations[cleanString(value)];
                        if (translated) td.innerText = translated;
                    }


                    if (value){
                        let label = labels[key] || key;
                        let translated = translations[label.toLowerCase().split(" ").join("_")];
                        label = translated || label;
                        return $("tr",$("td",""+label),td);
                    }
                }),
                    item.lastModified && $("tr",$("td",translations.lastModified),$("td",formatDate(item.lastModified)))
                ),
                $("h4",translations.description),
                $(".description",reportData.report_describ || reportData.description),
                $("h4",translations.comments),
                commentlist = $(".commentlist", renderComments(comments)),
                $("button.text.add.addcomment" + (comments.length?"":".up"),{onClick:()=>{
                        commentForm.style.display = "block";
                        setTimeout(()=>{
                            let textarea = commentForm.querySelector("textarea");
                            textarea.focus();
                            textarea.scrollIntoView({ behavior: "smooth"})
                        },10);
                    }},translations.addComment),
                commentForm = $("div.commentform",$("textarea"),
                    $("button.secondary.green.arrow",{onClick:()=>{
                            let comment = commentForm.querySelector("textarea").value;
                            if (comment){
                                commentlist.innerHTML = "";
                                commentlist.appendChild($(".spinner"));
                                incidentService.postComment(comment,item).then(()=>{
                                    getComments(reportData).then(comments=>{
                                        commentlist.innerHTML = "";
                                        commentlist.appendChild($("div",renderComments(comments)));
                                    });
                                });
                            }
                            commentForm.style.display = "none";
                        }},translations.save),
                    $("button.secondary.red.delete",{onClick:()=>{
                            commentForm.style.display = "none";
                        }},translations.cancel)
                )
            )*/



        }

        config.postProcessing = async (data)=>{
            //console.error("postProcessing",data);
        }

        config.postUpdate = async (data,result)=>{
            // auto assign to CSO
            let assignResult = await incidentService.assignToCSO(result,user.getUserOrganisation());
            result.info_id = assignResult.id;
            // and auto accept
            await incidentService.accept(result);
        }


        await api.list("organisations");
        await api.list("users");
        await masterDetail.render(container,config);
    }

    function showRejectForm(parent,item){
        let slidePanel = parent.querySelector(".slidepanel");
        let form = $(".actionform",
            {parent:slidePanel},
            $("h3",translations.reject),
            $(".content",
                $("p",translations.rejectReason),
                $("div", $("textarea",{placeholder:translations.reason})),
                $("button.secondary.blue.arrow",{
                    onClick:()=>{
                        let textarea = form.querySelector("textarea");
                        let reason = textarea.value;
                        if (reason){
                            incidentService.reject(item,reason).then(()=>{
                                masterDetail.refresh();
                                closeForm();
                                setTimeout(()=>{
                                    masterDetail.closeSidePanel();
                                },300);
                            });
                        }else{
                            textarea.classList.remove("error");
                            setTimeout(()=>{
                                textarea.classList.add("error");
                            },10);
                        }
                    }
                },translations.reject),
                $("button.secondary.red.delete",
                    {onClick:closeForm},
                    translations.cancel)
            )
        );
        parent.classList.add("active");
        setTimeout(()=>{
            form.classList.add("active");
        },10);

        function closeForm(){
            form.classList.remove("active");
            setTimeout(()=>{
                parent.classList.remove("active");
                slidePanel.removeChild(form);
            },300);
        }
    }

    function showDeleteForm(parent,item){
        let slidePanel = parent.querySelector(".slidepanel");
        let form = $(".actionform",
            {parent:slidePanel},
            $("h3",translations.delete),
            $(".content",
                $("p",translations.deleteReason),
                $("div", $("textarea",{placeholder:translations.reason})),
                $("button.secondary.blue.arrow",{
                    onClick:()=>{
                        let textarea = form.querySelector("textarea");
                        let reason = textarea.value;
                        if (reason){
                            incidentService.delete(item,reason).then(()=>{
                                masterDetail.refresh();
                                closeForm();
                                setTimeout(()=>{
                                    masterDetail.closeSidePanel();
                                },300);
                            });
                        }else{
                            textarea.classList.remove("error");
                            setTimeout(()=>{
                                textarea.classList.add("error");
                            },10);
                        }
                    }
                },translations.delete),
                $("button.secondary.red.delete",
                    {onClick:closeForm},
                    translations.cancel)
            )
        );
        parent.classList.add("active");
        setTimeout(()=>{
            form.classList.add("active");
        },10);

        function closeForm(){
            form.classList.remove("active");
            setTimeout(()=>{
                parent.classList.remove("active");
                slidePanel.removeChild(form);
            },300);
        }
    }

    function showCompleteForm(parent,item){
        let slidePanel = parent.querySelector(".slidepanel");
        let statusSelect,status ;
        let form = $(".actionform",
            {parent:slidePanel},
            $("h3",translations.mark_as_completed),
            $(".content",
                $("p",translations.sureToComplete),
                $("p",translations.selectCloseStatus),
                statusSelect = $("select",{
                        onchange:()=>{
                            status = statusSelect.value;
                        }
                    },
                    $("option",{value:""},translations.pleaseSelect),
                    $("option",{value:"Résolu"},translations.resolved),
                    $("option",{value:"Non résolu"},translations.notResolved),
                    $("option",{value:"Persistant"},translations.persistent),
                    $("option",{value:"Non-Suivi"},translations.notFollowedUp),
                ),
                $("button.secondary.green.check",{
                    onClick:()=>{
                        if (!status){
                            statusSelect.classList.remove("error");
                            setTimeout(()=>{
                                statusSelect.classList.add("error");
                            },10);
                            return;
                        }
                        incidentService.close(item,status).then(()=>{
                            closeForm();
                            setTimeout(()=>{
                                masterDetail.closeSidePanel();
                                masterDetail.refresh();
                                nav.animateUpdate("working","complete",1);
                            },300);
                        });
                    }
                },translations.mark_as_completed),
                $("button.secondary.red.delete",
                    {onClick:closeForm},
                    translations.cancel)
            )
        );
        parent.classList.add("active");
        setTimeout(()=>{
            form.classList.add("active");
        },10);

        function closeForm(){
            form.classList.remove("active");
            setTimeout(()=>{
                parent.classList.remove("active");
                slidePanel.removeChild(form);
            },300);
        }
    }

    function showReOpenForm(parent,item){
        let slidePanel = parent.querySelector(".slidepanel");
        let statusSelect,status ;
        let form = $(".actionform",
            {parent:slidePanel},
            $("h3",translations.reopen),
            $(".content",
                $("p",translations.sureToReopen),
                $("button.secondary.green.check",{
                    onClick:()=>{
                        incidentService.reopen(item,status).then(()=>{
                            closeForm();
                            setTimeout(()=>{
                                masterDetail.closeSidePanel();
                                masterDetail.refresh();
                                nav.animateUpdate("complete","working",1);
                            },300);
                        });
                    }
                },translations.mark_as_open),
                $("button.secondary.red.delete",
                    {onClick:closeForm},
                    translations.cancel)
            )
        );
        parent.classList.add("active");
        setTimeout(()=>{
            form.classList.add("active");
        },10);

        function closeForm(){
            form.classList.remove("active");
            setTimeout(()=>{
                parent.classList.remove("active");
                slidePanel.removeChild(form);
            },300);
        }
    }




    return me;
})();
export default IncidentsAssigned;