let Touch = ()=>{
    let me = {};
    let touchData = {};

    me.init = ()=>{
        document.body.addEventListener("pointerdown",handleTouchDown);
        document.body.addEventListener("pointermove",handleTouchMove);
        document.body.addEventListener("pointerup",handleTouchUp);
    }

    function handleTouchDown(event){
        touchData.isDown = true;
        touchData.startX = event.clientX;
        touchData.startY = event.clientY;

        let clickTarget = event.target.closest(".handle");
        if (clickTarget && clickTarget.onClick) clickTarget.onClick(event,clickTarget);
        touchData.clickTarget = clickTarget;
    }

    function handleTouchMove(event){

    }

    function handleTouchUp(event){
        if (touchData.isDown){
            if (touchData.clickTarget && touchData.clickTarget.onUp){
                touchData.clickTarget.onUp(event,touchData.clickTarget);
            }
        }
    }

    return me;
}

export default Touch();